import { Dialogs } from '~/types/enum';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { addDialog } = useDialogStore();

  const { profile } = useProfileStore();

  const isMFAEnabled = profile.value?.CSCUserOTPSec === 'true';

  if (!isMFAEnabled) {
    requestAnimationFrame(() => {
      addDialog(Dialogs.Notification, false, {
        title: 'Multi Factor Authentication required',
        body: 'Multi Level Authentication (MFA) is required to access this page. Please enable it in your profile.',
        showCancelButton: true,
        redirectOnConfirmTo: { name: 'Profile' },
      });
    });

    return navigateTo({ name: 'Dashboard' });
  }

  const { data } = await isLoggedIn();

  if (!data?.mfa || (data?.mfa && !data?.isFresh)) {
    requestAnimationFrame(() => {
      addDialog(Dialogs.Profile.MFAReLogin, false, {
        type: 'access',
        returnRoute: to.fullPath,
        reload: !from.name,
      });
    });

    return navigateTo({ name: 'Dashboard' });
  }
});
